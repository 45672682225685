import React from "react";

import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import { CODE_TO_COUNTRY, COUNTRY_CODES } from "~/utils/data/constants";
import { Locale } from "~/utils/interfaces/Locale";
import { Sector } from "~/utils/interfaces/Places";

type LeftHeaderProps = {
  country: Locale;
  sector: Sector | null;
  onSetCountry: (country: Locale) => void;
};

const LeftHeader = ({ country, sector, onSetCountry }: LeftHeaderProps) => {
  return (
    <>
      <div className="flex flex-col justify-start mb-2">
        <p className="text-sm">Cambiar país:</p>
        <Select
          value={country}
          onChange={(e) => onSetCountry(e.target.value as Locale)}
        >
          {COUNTRY_CODES.map((_country) => (
            <MenuItem
              key={_country}
              value={_country}
            >
              {CODE_TO_COUNTRY[_country]}
            </MenuItem>
          ))}
        </Select>
      </div>
      <h1 className="mb-1">Sectores de {CODE_TO_COUNTRY[country]}</h1>
    </>
  );
};

export default LeftHeader;
