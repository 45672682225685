import React from "react";

import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { Link, navigate } from "gatsby";

import PrivateRoute from "~/components/Authentication/PrivateRoute";
import Flex from "~/components/Containers/Flex";
import { SectionHeader, SpacedPaper } from "~/components/Containers/SpacedPaper";
import LoadingError from "~/components/Loaders/LoadingError";
import DisplayBlockedTimeBlock from "~/components/Timeblocks/DisplayBlockedTimeBlock";
import DisplayTimeBlock from "~/components/Timeblocks/DisplayTimeBlock";
import Bold from "~/components/Typography/Bold";
import type { Nurse } from "~/utils/interfaces/Nurse";
import type { GroupedBlockedTimeBlocks, GroupedTimeBlocks } from "~/utils/interfaces/Timeblock";

interface NurseTimeblocksProps {
  loading: boolean;
  error: any;
  nurseData: Nurse | null;
  activeTimeblocks: GroupedTimeBlocks;
  blockedTimeblocks: GroupedBlockedTimeBlocks;
}

const NurseTimeblocks = ({ loading, error, nurseData, activeTimeblocks, blockedTimeblocks }: NurseTimeblocksProps) => {
  return (
    <PrivateRoute>
      <LoadingError
        loading={loading}
        error={error}
      />
      {!loading && nurseData && (
        <>
          <SpacedPaper variant="outlined">
            <Flex direction="column">
              <SectionHeader>
                <Bold>Horarios habilitados</Bold>
                <ButtonGroup variant="text">
                  <Button
                    color="primary"
                    onClick={async () => navigate("/dashboard/")}
                  >
                    Ver agenda
                  </Button>
                  <Link
                    to={`/health-team/${nurseData.id}/schedule/`}
                    style={{ textDecoration: "none" }}
                  >
                    <Button color="primary">Editar</Button>
                  </Link>
                </ButtonGroup>
              </SectionHeader>
              <hr />
              <DisplayTimeBlock timeblocks={activeTimeblocks} />
            </Flex>
          </SpacedPaper>
          <SpacedPaper variant="outlined">
            <Flex direction="column">
              <SectionHeader>
                <Bold>Horarios bloqueados</Bold>
                <Link
                  to={`/health-team/${nurseData.id}/block-schedule/`}
                  style={{ textDecoration: "none" }}
                >
                  <Button color="primary">Editar</Button>
                </Link>
              </SectionHeader>
              <hr />
              <DisplayBlockedTimeBlock timeblocks={blockedTimeblocks} />
            </Flex>
          </SpacedPaper>
        </>
      )}
    </PrivateRoute>
  );
};

export default NurseTimeblocks;
