import React, { useEffect, useMemo, useRef, useState } from "react";

import { useGoogleMap } from "@react-google-maps/api";
import clsx from "clsx";
import { HiOutlinePencilAlt, HiSave } from "react-icons/hi";
import Swal from "sweetalert2";

import OverlayView from "~/components/Sectors/helpers/OverlayView";
import sectorsAPI from "~/utils/api/v2/places";
import type { Coordinate } from "~/utils/interfaces/Places";

interface LocationFeeMarkerProps {
  sectorId: string;
  centroid: Coordinate;
  hide?: boolean;
  fee: number;
  minimal?: boolean;
  className?: string;
  fetchSectorsCallback: () => Promise<void>;
}

const LocationFeeMarker = ({
  sectorId,
  centroid,
  hide,
  fee,
  minimal,
  className,
  fetchSectorsCallback,
}: LocationFeeMarkerProps) => {
  const [newValue, setNewValue] = useState<number>(fee);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const map = useGoogleMap();

  const updateSector = async () => {
    setIsLoading(true);
    const updatedSector = await sectorsAPI.updateSector(sectorId, {
      location_fee: newValue,
    });
    if (updatedSector) {
      await fetchSectorsCallback();
    } else {
      Swal.fire({
        title: "Ocurrió un error :(",
        text: "Ocurrió un error editando la tarifa del sector.",
        icon: "error",
      });
    }
    setIsLoading(false);
  };

  const isNumeric = (value: string) => {
    if (typeof value != "string") return false;
    return !isNaN(value as unknown as number) && !isNaN(parseFloat(value));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = event.target;
    value = value.replace(/\D/g, "");
    if (value === "") value = "0";
    if (isNumeric(value)) {
      setNewValue(Number(value));
    }
  };

  const handleKeyDown = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && newValue != fee) {
      await updateSector();
    }
  };

  useEffect(() => {
    setNewValue(fee);
  }, [fee]);

  const isEditing = useMemo(() => fee != newValue, [fee, newValue]);

  const displayedValue = useMemo(() => {
    let value = fee;
    if (typeof newValue === "number") {
      value = newValue;
    }
    return value;
  }, [fee, newValue]);

  if (hide || !map) return null;

  return (
    <OverlayView
      position={centroid}
      map={map}
    >
      <div
        className={clsx(
          "w-fit",
          "flex items-center justify-between gap-x-1",
          "px-2 py-1.5",
          "bg-examedi-gray-3",
          "text-examedi-gray-6 text-sm font-light",
          "rounded-lg",
          "shadow-md",
          "group",
          "transition-all duration-150 ease-in-out",
          isLoading && "!opacity-60",
          minimal && "scale-75",
          className,
        )}
      >
        {!minimal && "Tarifa:"}
        <input
          ref={inputRef}
          type="text"
          value={"$" + displayedValue}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          disabled={isLoading}
          className={clsx(
            "w-[3rem]",
            "bg-transparent",
            "text-sm text-examedi-gray-6 font-light",
            "outline-none",
            "cursor-pointer",
          )}
        />
        <div
          className={clsx(
            "w-0",
            "bg-examedi-gray-3",
            "rounded-r-md shadow-md",
            "group-hover:w-5",
            "transition-all duration-150 ease-in-out",
            isEditing && "!w-5",
            minimal && "!hidden",
          )}
          onClick={() => {
            if (!isEditing) {
              inputRef.current?.focus();
            } else {
              updateSector();
            }
          }}
        >
          {!isEditing && (
            <HiOutlinePencilAlt
              size={20}
              color="white"
              strokeWidth={1}
              className="opacity-0 group-hover:!opacity-100"
            />
          )}
          {isEditing && (
            <HiSave
              size={20}
              color="white"
              strokeWidth={1}
            />
          )}
        </div>
      </div>
    </OverlayView>
  );
};

export default LocationFeeMarker;
