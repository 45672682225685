import React, { useCallback, useEffect, useState } from "react";

import Button from "@material-ui/core/Button";

import NurseInformation from "~/components/Sectors/NurseDetail/NurseInformation";
import NurseTimeblocks from "~/components/Sectors/NurseDetail/NurseTimeblocks";
import nurseService from "~/utils/api/v1/nurseService";
import timeBlockService from "~/utils/api/v1/timeblockService";
import { nurseAPI } from "~/utils/api/v2";
import type { Laboratory } from "~/utils/interfaces/Laboratory";
import type { Locale } from "~/utils/interfaces/Locale";
import type { Nurse } from "~/utils/interfaces/Nurse";
import type { GroupedBlockedTimeBlocks, GroupedTimeBlocks } from "~/utils/interfaces/Timeblock";
import { groupBlockedTimeBlocks, groupTimeBlocks } from "~/utils/timeblocks/group";

type NurseDetailProps = {
  nurse: Nurse | null;
  country: Locale;
  onToggleShowAllNurseSectors: () => void;
};

const NurseDetail = ({ nurse, country, onToggleShowAllNurseSectors }: NurseDetailProps) => {
  const [viewingAllSectors, setViewingAllSectors] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>({});
  const [nurseData, setNurseData] = useState<Nurse | null>(null);
  const [activeTimeblocks, setActiveTimeblocks] = useState<GroupedTimeBlocks>({});
  const [blockedTimeblocks, setBlockedTimeblocks] = useState<GroupedBlockedTimeBlocks>({});
  const [nurseLabs, setNurseLabs] = useState<Laboratory[]>([]);

  const fetchNurseData = useCallback(async () => {
    if (!nurse) return;

    const nurseDetails = await nurseService.fetchNurseProfile(nurse.id);
    setNurseData(nurseDetails.data);
  }, [nurse]);

  useEffect(() => {
    if (!nurse) return;

    const fetchData = async () => {
      setLoading(true);
      try {
        await fetchNurseData();
        const responses = await Promise.all([
          timeBlockService.fetchNurseTimeBlocks(nurse.id),
          nurseAPI.fetchNurseLabs(nurse.id),
        ]);
        const [timeblocksRes, labs] = responses;
        setActiveTimeblocks(groupTimeBlocks(timeblocksRes.data.data.timeblocks));
        setBlockedTimeblocks(groupBlockedTimeBlocks(timeblocksRes.data.data.blocked) as GroupedBlockedTimeBlocks);
        setNurseLabs(labs || []);
      } catch (err) {
        setError(err);
      }
      setLoading(false);
    };
    fetchData();
  }, [nurse]);

  useEffect(() => {
    if (!nurse) setViewingAllSectors(false);
  }, [nurse]);

  if (!nurse) return null;

  return (
    <div className="w-full flex flex-col gap-y-2">
      <h3>Información de {nurse.names}</h3>
      <div className="w-fit">
        {viewingAllSectors ? (
          <Button
            variant="contained"
            color="primary"
            style={{ color: "black" }}
            onClick={() => {
              setViewingAllSectors(false);
              onToggleShowAllNurseSectors();
            }}
          >
            Volver a sector seleccionado
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            style={{ color: "black" }}
            onClick={() => {
              setViewingAllSectors(true);
              onToggleShowAllNurseSectors();
            }}
          >
            Ver sus sectores
          </Button>
        )}
      </div>
      {!!nurseData && (
        <NurseInformation
          country={country}
          nurseData={nurseData}
          nurseLabs={nurseLabs}
          fetchNurseDataCallback={fetchNurseData}
        />
      )}
      <NurseTimeblocks
        loading={loading}
        error={error}
        nurseData={nurseData}
        activeTimeblocks={activeTimeblocks}
        blockedTimeblocks={blockedTimeblocks}
      />
    </div>
  );
};

export default NurseDetail;
