import React, { useCallback, useEffect, useMemo, useState } from "react";

import clsx from "clsx";
import { BeatLoader } from "react-spinners";

import { getSectorItems } from "~/utils/api/v2/items";
import sectorsAPI from "~/utils/api/v2/places";
import type { ItemSummary } from "~/utils/interfaces/Services";

type ItemAvailabilityButtonProps = {
  sectorId: string;
  itemId: string;
  isBlocked?: boolean;
  fetchSectorItemsCallback: () => Promise<void>;
};

const ItemAvailabilityButton = ({
  sectorId,
  itemId,
  isBlocked,
  fetchSectorItemsCallback,
}: ItemAvailabilityButtonProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleBlockItemInSector = async () => {
    setLoading(true);
    await sectorsAPI.blockItemSector(sectorId, itemId);
    await fetchSectorItemsCallback();
    setLoading(false);
  };

  const handleUnblockItemInSector = async () => {
    setLoading(true);
    await sectorsAPI.unblockItemSector(sectorId, itemId);
    await fetchSectorItemsCallback();
    setLoading(false);
  };

  if (isBlocked) {
    return (
      <button
        className={clsx("text-white text-center", "col-span-2 text-right text-sm", "bg-green-500 rounded-md shadow-sm")}
        onClick={handleUnblockItemInSector}
        disabled={loading}
      >
        {loading ? (
          <BeatLoader
            color="#ffffff"
            loading
            margin={0}
            size={13}
          />
        ) : (
          "Desbloquear"
        )}
      </button>
    );
  }

  return (
    <button
      className={clsx("text-white text-center", "col-span-2 text-right text-sm", "bg-red-500 rounded-md shadow-sm")}
      onClick={handleBlockItemInSector}
      disabled={loading}
    >
      {loading ? (
        <BeatLoader
          color="#ffffff"
          loading
          margin={0}
          size={13}
        />
      ) : (
        "Bloquear"
      )}
    </button>
  );
};

type ItemsProps = {
  selecteSectorId: string;
};

const Items = ({ selecteSectorId }: ItemsProps) => {
  const [currentFilter, setCurrentFilter] = useState<string>("");
  const [items, setItems] = useState<ItemSummary[]>([]);
  const [blockedItems, setBlockedItems] = useState<ItemSummary[]>([]);

  const [filteredItems, filteredBlockedItems] = useMemo(() => {
    const loweredFilter = currentFilter.toLowerCase();
    if (currentFilter) {
      const _filteredItems = items.filter(({ name }) => name.toLowerCase().includes(loweredFilter));
      const _filteredBlockedItems = blockedItems.filter(({ name }) => name.toLowerCase().includes(loweredFilter));
      return [_filteredItems, _filteredBlockedItems];
    } else {
      return [items, blockedItems];
    }
  }, [currentFilter, items, blockedItems]);

  const getAllSectorItems = useCallback(async () => {
    const allSectorItems = await getSectorItems(selecteSectorId);
    if (allSectorItems) {
      const { items, blocked_items } = allSectorItems;
      setItems(items);
      setBlockedItems(blocked_items);
    }
  }, [selecteSectorId]);

  useEffect(() => {
    getAllSectorItems();
  }, [getAllSectorItems]);

  return (
    <div className="w-full flex flex-col gap-y-2">
      <div className="flex flex-col justify-start items-start gap-y-0">
        <h2>Servicios</h2>
      </div>
      <input
        type="text"
        className="w-1/2 p-1 border-2 border-gray-500 rounded-lg"
        placeholder="Filtrar por nombre"
        onChange={(e) => setCurrentFilter(e.target.value as string)}
      />
      <div
        className={clsx(
          "w-full h-72 px-2 py-2",
          "flex flex-col items-center gap-y-2",
          "bg-white border rounded-lg",
          "overflow-y-scroll",
        )}
      >
        <p className="w-full text-left text-xl font-bold">Activos</p>
        {!items.length && !currentFilter && (
          <p className="w-full text-left">No hay servicios asignados en este sector</p>
        )}
        {!filteredItems.length && !!currentFilter && (
          <p className="w-full text-left">No hay servicios con ese nombre asignados en este sector</p>
        )}
        {filteredItems.map((item) => (
          <div
            key={item.id}
            className={clsx("w-full grid grid-cols-12", "py-2 px-3", "rounded-lg", "border", "bg-gray-100")}
          >
            <div className="col-span-10 truncate text-gray-700">{item.name}</div>
            <ItemAvailabilityButton
              sectorId={selecteSectorId}
              itemId={item.id}
              fetchSectorItemsCallback={getAllSectorItems}
            />
          </div>
        ))}
        <p className="w-full mt-3 text-left text-xl font-bold">Bloqueados</p>
        {!blockedItems.length && !currentFilter && (
          <p className="w-full text-left">No hay servicios bloqueados en este sector</p>
        )}
        {!filteredBlockedItems.length && !!currentFilter && (
          <p className="w-full text-left">No hay servicios con ese nombre bloqueados en este sector</p>
        )}
        {filteredBlockedItems.map((item) => (
          <div
            key={item.id}
            className={clsx("w-full grid grid-cols-12", "py-2 px-3", "rounded-lg", "border", "bg-gray-100")}
          >
            <div className="col-span-10 truncate text-gray-700">{item.name}</div>
            <ItemAvailabilityButton
              sectorId={selecteSectorId}
              itemId={item.id}
              isBlocked
              fetchSectorItemsCallback={getAllSectorItems}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Items;
