import React from "react";

import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import { LOCALITY_CONFIG } from "~/utils/constants/sectors";
import { Locale } from "~/utils/interfaces/Locale";

type LandmarkSelectProps = {
  country: Locale;
  currentLandmark: string;
  onSetLandmark: (country: Locale) => void;
};

const LandmarkSelect = ({ country, currentLandmark, onSetLandmark }: LandmarkSelectProps) => {
  return (
    <div className="flex flex-col justify-start mb-2">
      <p className="text-sm">Cambiar grupo:</p>
      <Select
        value={currentLandmark}
        onChange={(e) => onSetLandmark(e.target.value as Locale)}
      >
        {Object.keys(LOCALITY_CONFIG[country]).map((landmark) => (
          <MenuItem
            key={landmark}
            value={landmark}
          >
            {landmark}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default LandmarkSelect;
