import React from "react";

import Chip from "@material-ui/core/Chip";

import { dayNums, daysToSpanish } from "~/utils/dates/names";
import { GroupedTimeBlocks, TimeBlock } from "~/utils/interfaces/Timeblock";

const DayContainer = ({ children }: { children: React.ReactNode }) => (
  <div className="flex flex-col items-center gap-2">{children}</div>
);

interface Props {
  timeblocks: GroupedTimeBlocks;
}

const DisplayTimeBlock = ({ timeblocks }: Props): JSX.Element => {
  const displayDay = (dayName: string, day: TimeBlock[][]): JSX.Element => {
    const blocks = day.map((block: TimeBlock[]) => {
      const start = block[0].begin_hour;
      const length = block.length;
      const end = block[length - 1].end_hour;

      return (
        <Chip
          key={block[0].id}
          variant="outlined"
          color="primary"
          label={`${start} - ${end}`}
        />
      );
    });

    return (
      <DayContainer key={dayName}>
        <h4>{daysToSpanish[dayName]}</h4>
        {blocks}
      </DayContainer>
    );
  };

  const displayBlocks = (): JSX.Element[] => {
    const days = [
      <DayContainer key={0}>
        <h4>Lunes</h4>
      </DayContainer>,
      <DayContainer key={1}>
        <h4>Martes</h4>
      </DayContainer>,
      <DayContainer key={2}>
        <h4>Miércoles</h4>
      </DayContainer>,
      <DayContainer key={3}>
        <h4>Jueves</h4>
      </DayContainer>,
      <DayContainer key={4}>
        <h4>Viernes</h4>
      </DayContainer>,
      <DayContainer key={5}>
        <h4>Sábado</h4>
      </DayContainer>,
      <DayContainer key={6}>
        <h4>Domingo</h4>
      </DayContainer>,
    ];

    for (const [key, value] of Object.entries(timeblocks)) {
      days[dayNums[key as keyof typeof dayNums]] = displayDay(key, value);
    }
    return days;
  };

  return <div className="grid grid-cols-7 gap-x-8 overflow-x-auto p-4">{displayBlocks()}</div>;
};

export default DisplayTimeBlock;
