import React, { useEffect, useMemo, useState } from "react";

import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import clsx from "clsx";
import { navigate } from "gatsby";
import Swal from "sweetalert2";

import Flex from "~/components/Containers/Flex";
import { SectionHeader, SpacedPaper } from "~/components/Containers/SpacedPaper";
import Bold from "~/components/Typography/Bold";
import nurseService from "~/utils/api/v1/nurseService";
import { medicalActionAPI, nurseAPI } from "~/utils/api/v2";
import { listMedicalServices } from "~/utils/api/v2/items";
import type { Laboratory } from "~/utils/interfaces/Laboratory";
import type { Locale } from "~/utils/interfaces/Locale";
import type { EditableActionsAndServices, Nurse, Specialty } from "~/utils/interfaces/Nurse";
import type { Item, MedicalAction } from "~/utils/interfaces/Services";

type HeaderProps = {
  editing: boolean;
  nurseData: Nurse;
  handleCancel: () => void;
  handleActivate: () => void;
  handleUpdateNurse: () => void;
  onSetEditing: (value: boolean) => void;
};

const Header = ({ editing, nurseData, handleCancel, handleActivate, handleUpdateNurse, onSetEditing }: HeaderProps) => {
  return (
    <SectionHeader>
      <Bold>Datos personales</Bold>
      {editing ? (
        <ButtonGroup variant="text">
          <Button
            color="secondary"
            onClick={handleCancel}
          >
            Cancelar
          </Button>
          <Button
            color="primary"
            onClick={handleUpdateNurse}
          >
            Actualizar
          </Button>
        </ButtonGroup>
      ) : (
        <ButtonGroup variant="text">
          {nurseData.active ? (
            <Button
              color="secondary"
              onClick={handleActivate}
            >
              Desactivar
            </Button>
          ) : (
            <Button
              color="primary"
              onClick={handleActivate}
            >
              Activar
            </Button>
          )}
          <Button
            color="primary"
            onClick={() => {
              onSetEditing(true);
            }}
          >
            Editar
          </Button>
          <Button
            color="primary"
            onClick={async () =>
              navigate(`/health-team/${nurseData.id}/exams/`, {
                state: nurseData,
              })
            }
          >
            Detalle exámenes
          </Button>
        </ButtonGroup>
      )}
    </SectionHeader>
  );
};

type ActionAndServiceGeneric<T> = T & { id: string; name: string };

type ActionAndServicesSelectorProps<T> = {
  all: ActionAndServiceGeneric<T>[];
  previouslySelected: ActionAndServiceGeneric<T>[];
  newlySelected: ActionAndServiceGeneric<T>[];
  newlyRemoved: ActionAndServiceGeneric<T>[];
  withSearch?: boolean;
  onNewSelect: (value: ActionAndServiceGeneric<T>) => void;
  onNewRemove: (value: ActionAndServiceGeneric<T>) => void;
};

const ActionAndServicesSelector = <T,>({
  all,
  previouslySelected,
  newlySelected,
  newlyRemoved,
  withSearch,
  onNewSelect,
  onNewRemove,
}: ActionAndServicesSelectorProps<T>) => {
  const [currentFilter, setCurrentFilter] = useState<string>("");

  const allFilteredElements = useMemo(() => {
    const loweredFilter = currentFilter.toLowerCase();
    if (currentFilter) {
      const filteredActionsServices = all.filter(({ name }) => {
        return name.toLowerCase().includes(loweredFilter);
      });
      return filteredActionsServices;
    } else {
      return all;
    }
  }, [currentFilter, all]);

  const checkIfChecked = (selectedId: string) => {
    if (!previouslySelected.some(({ id }) => id === selectedId) && newlySelected.some(({ id }) => id === selectedId)) {
      return true;
    }
    if (previouslySelected.some(({ id }) => id === selectedId) && !newlyRemoved.some(({ id }) => id === selectedId)) {
      return true;
    }
    return false;
  };

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>, element: ActionAndServiceGeneric<T>) => {
    const wasSelectedBefore = previouslySelected.some(({ id }) => id === element.id);
    const selectedButNotSaved = newlySelected.some(({ id }) => id === element.id);
    const removedButNotSaved = newlyRemoved.some(({ id }) => id === element.id);
    const checked = event.target.checked;
    if (checked) {
      if (!wasSelectedBefore) onNewSelect(element);
      if (removedButNotSaved) onNewRemove(element);
    } else {
      if (wasSelectedBefore) onNewRemove(element);
      if (selectedButNotSaved) onNewSelect(element);
    }
  };

  return (
    <>
      {withSearch && (
        <input
          type="text"
          className={clsx("w-1/2 p-1", "border-2 border-gray-500 rounded-lg")}
          placeholder="Buscar por nombre"
          onChange={(e) => setCurrentFilter(e.target.value as string)}
        />
      )}
      <div className="w-full max-h-[18rem] h-fit overflow-y-scroll">
        {allFilteredElements.map((element) => (
          <div
            key={element.id}
            className="w-full grid grid-cols-8 place-items-center"
          >
            <Checkbox
              className="col-span-1"
              checked={checkIfChecked(element.id)}
              onChange={(e) => handleCheck(e, element)}
            />
            <div className="w-full col-span-7 text-left">{element.name}</div>
          </div>
        ))}
      </div>
    </>
  );
};

type EditableDataProps = {
  editing: boolean;
  nurseData: Nurse;
  country: Locale;
  editableData: Nurse;
  editableActionsServices: EditableActionsAndServices;
  onSetEditableData: (value: Nurse) => void;
  onSetEditableActionsServices: (value: EditableActionsAndServices) => void;
};

const EditableData = ({
  editing,
  nurseData,
  country,
  editableData,
  editableActionsServices,
  onSetEditableData,
  onSetEditableActionsServices,
}: EditableDataProps) => {
  const [specialtyOptions, setSpecialtyOptions] = useState<Specialty[]>([]);
  const [allMedicalActions, setAllMedicalActions] = useState<MedicalAction[]>([]);
  const [nurseMedicalActions, setNurseMedicalActions] = useState<MedicalAction[]>([]);
  const [allMedicalServices, setAllMedicalServices] = useState<Item[]>([]);
  const [nurseMedicalServices, setNurseMedicalServices] = useState<Item[]>([]);

  // Fetch constant data
  useEffect(() => {
    const fetchConstantData = async () => {
      // All medical actions and services
      const [medicalServices, medicalActions] = await Promise.all([
        listMedicalServices(country),
        medicalActionAPI.list(country),
      ]);
      setAllMedicalActions(medicalActions.data || []);
      setAllMedicalServices(medicalServices || []);

      // All specialties
      const res = await nurseService.fetchNurseSpecialties();
      setSpecialtyOptions(res.data);
    };
    fetchConstantData();
  }, []);

  // Fetch nurse specific data
  useEffect(() => {
    const fetchNurseActionsAndServices = async () => {
      if (!nurseData) return;

      const [selectedMedicalServices, selectedMedicalActions] = await Promise.all([
        nurseAPI.listMedicalServices(nurseData.id),
        nurseAPI.listMedicalActions(nurseData.id),
      ]);

      setNurseMedicalActions(selectedMedicalActions.data || []);
      setNurseMedicalServices(selectedMedicalServices.data || []);
    };

    fetchNurseActionsAndServices();
  }, [nurseData, country]);

  const renderSelectedOptions = (selected: string[] | string | any) => {
    return (
      <Flex wrap="wrap">
        {(selected as string[]).map((value) => (
          <Chip
            variant="outlined"
            color="primary"
            key={value}
            label={value}
          />
        ))}
      </Flex>
    );
  };

  if (editing) {
    return (
      <>
        <div className="w-full flex flex-col items-start gap-y-2">
          <p className="font-bold text-xl">Especialidades</p>
          <Select
            multiple
            fullWidth
            value={editableData.specialties.map((specialty) => specialty.name)}
            onChange={(event) => {
              const newOptions = event.target.value as string[];
              onSetEditableData({
                ...editableData,
                specialties: specialtyOptions.filter(({ name }) => newOptions.includes(name)),
              });
            }}
            input={<Input id="select-specialties" />}
            renderValue={renderSelectedOptions}
          >
            {specialtyOptions?.map((specialty) => (
              <MenuItem
                key={specialty.id}
                value={specialty.name}
              >
                {specialty.name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className="w-full flex flex-col items-start gap-y-2">
          <p className="font-bold text-xl">Acciones Médicas</p>
          <ActionAndServicesSelector
            all={allMedicalActions}
            previouslySelected={nurseMedicalActions}
            newlySelected={editableActionsServices.medicalActionsToAdd}
            newlyRemoved={editableActionsServices.medicalActionsToRemove}
            onNewSelect={(value: MedicalAction) => {
              const wasBefore = editableActionsServices.medicalActionsToAdd.some(({ id }) => value.id === id);
              if (wasBefore) {
                onSetEditableActionsServices({
                  ...editableActionsServices,
                  medicalActionsToAdd: editableActionsServices.medicalActionsToAdd.filter(({ id }) => id !== value.id),
                });
              } else {
                onSetEditableActionsServices({
                  ...editableActionsServices,
                  medicalActionsToAdd: [...editableActionsServices.medicalActionsToAdd, value],
                });
              }
            }}
            onNewRemove={(value: MedicalAction) => {
              const wasBefore = editableActionsServices.medicalActionsToRemove.some(({ id }) => value.id === id);
              if (wasBefore) {
                onSetEditableActionsServices({
                  ...editableActionsServices,
                  medicalActionsToRemove: editableActionsServices.medicalActionsToRemove.filter(
                    ({ id }) => id !== value.id,
                  ),
                });
              } else {
                onSetEditableActionsServices({
                  ...editableActionsServices,
                  medicalActionsToRemove: [...editableActionsServices.medicalActionsToRemove, value],
                });
              }
            }}
          />
        </div>
        <div className="w-full flex flex-col items-start gap-y-2">
          <p className="font-bold text-xl">Servicios Médicos</p>
          <ActionAndServicesSelector
            all={allMedicalServices}
            previouslySelected={nurseMedicalServices}
            newlySelected={editableActionsServices.medicalServicesToAdd}
            newlyRemoved={editableActionsServices.medicalServicesToRemove}
            onNewSelect={(value: Item) => {
              const wasBefore = editableActionsServices.medicalServicesToAdd.some(({ id }) => value.id === id);
              if (wasBefore) {
                onSetEditableActionsServices({
                  ...editableActionsServices,
                  medicalServicesToAdd: editableActionsServices.medicalServicesToAdd.filter(
                    ({ id }) => id !== value.id,
                  ),
                });
              } else {
                onSetEditableActionsServices({
                  ...editableActionsServices,
                  medicalServicesToAdd: [...editableActionsServices.medicalServicesToAdd, value],
                });
              }
            }}
            onNewRemove={(value: Item) => {
              const wasBefore = editableActionsServices.medicalServicesToRemove.some(({ id }) => value.id === id);
              if (wasBefore) {
                onSetEditableActionsServices({
                  ...editableActionsServices,
                  medicalServicesToRemove: editableActionsServices.medicalServicesToRemove.filter(
                    ({ id }) => id !== value.id,
                  ),
                });
              } else {
                onSetEditableActionsServices({
                  ...editableActionsServices,
                  medicalServicesToRemove: [...editableActionsServices.medicalServicesToRemove, value],
                });
              }
            }}
            withSearch
          />
        </div>
      </>
    );
  }

  return (
    <>
      <div className="w-full flex flex-col items-start gap-y-2">
        <p className="font-bold text-xl">Especialidades</p>
        <div className="flex flex-wrap gap-2">
          {!nurseData.specialties.length && "No tiene especialidades"}
          {nurseData.specialties.map((speciality: Specialty) => (
            <Chip
              variant="outlined"
              color="primary"
              key={speciality.name}
              label={speciality.name}
            />
          ))}
        </div>
      </div>
      <div className="w-full flex flex-col items-start gap-y-2">
        <p className="font-bold text-xl">Acciones Médicas</p>
        <div className="flex flex-wrap gap-2">
          {nurseMedicalActions.map((medicalAction) => (
            <Chip
              key={medicalAction.id}
              variant="outlined"
              color="primary"
              label={medicalAction.name}
            />
          ))}
        </div>
      </div>
      <div className="w-full flex flex-col items-start gap-y-2">
        <p className="font-bold text-xl">Servicios Médicos</p>
        <div className="flex flex-wrap gap-2">
          {nurseMedicalServices.map((medicalService) => (
            <Chip
              key={medicalService.id}
              variant="outlined"
              color="primary"
              label={medicalService.name}
            />
          ))}
        </div>
      </div>
    </>
  );
};

type NurseInformationProps = {
  country: Locale;
  nurseData: Nurse;
  nurseLabs: Laboratory[];
  fetchNurseDataCallback: () => Promise<void>;
};

const NurseInformation = ({
  country,
  nurseData,
  nurseLabs,
  fetchNurseDataCallback,
}: NurseInformationProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(false);
  const [editableNurse, setEditableNurse] = useState<Nurse>(nurseData);
  const [editableActionsServices, setEditableActionsServices] = useState<EditableActionsAndServices>({
    medicalActionsToAdd: [],
    medicalActionsToRemove: [],
    medicalServicesToAdd: [],
    medicalServicesToRemove: [],
  });

  const updateNurse = async () => {
    setLoading(true);
    const { medicalActionsToAdd, medicalActionsToRemove, medicalServicesToAdd, medicalServicesToRemove } =
      editableActionsServices;
    await nurseService.updateNurse(nurseData.id, {
      specialties: editableNurse.specialties.map((specialty) => specialty.name),
      fake: editableNurse.fake,
      fingerprint_available: editableNurse.fingerprint_available,
    });
    if (medicalActionsToAdd.length) {
      await nurseAPI.addMedicalActions(
        nurseData.id,
        medicalActionsToAdd.map(({ id }) => id),
      );
    }
    if (medicalActionsToRemove.length) {
      await nurseAPI.deleteMedicalActions(
        nurseData.id,
        medicalActionsToRemove.map(({ id }) => id),
      );
    }
    if (medicalServicesToAdd.length) {
      await nurseAPI.addMedicalServices(
        nurseData.id,
        medicalServicesToAdd.map(({ id }) => id),
      );
    }
    if (medicalServicesToRemove.length) {
      await nurseAPI.deleteMedicalServices(
        nurseData.id,
        medicalServicesToRemove.map(({ id }) => id),
      );
    }
    await fetchNurseDataCallback();
    setEditing(false);
    setLoading(false);
    setEditableActionsServices({
      medicalActionsToAdd: [],
      medicalActionsToRemove: [],
      medicalServicesToAdd: [],
      medicalServicesToRemove: [],
    });
  };

  const toggleActivate = async () => {
    const check = await Swal.fire({
      title: "¿Estás seguro?",
      icon: "warning",
      showCancelButton: true,
    });
    if (!check.isConfirmed) return;
    setLoading(true);
    await nurseService.activateNurse(nurseData.id, {
      active: !nurseData.active,
    });
    await fetchNurseDataCallback();
    setLoading(false);
  };

  const handleCancel = () => {
    setEditableNurse(nurseData);
    setEditing(false);
  };

  useEffect(() => {
    setEditableNurse(nurseData);
  }, [nurseData]);

  return (
    <SpacedPaper variant="outlined">
      <Flex direction="column">
        <Header
          nurseData={nurseData}
          editing={editing}
          onSetEditing={(value: boolean) => setEditing(value)}
          handleActivate={toggleActivate}
          handleCancel={handleCancel}
          handleUpdateNurse={updateNurse}
        />
        <hr />
        {loading && (
          <Flex
            justify="center"
            align="center"
            padding="3rem"
          >
            <CircularProgress />
          </Flex>
        )}
        {!loading && (
          <div className="w-full flex flex-col gap-y-4 px-6 py-3">
            <div className="w-full flex flex-col">
              <div className="w-full grid grid-cols-12">
                <p className="col-span-3 font-medium">Nombre:</p>
                <p className="col-span-9">
                  {nurseData.names} {nurseData.last_names}
                </p>
              </div>
              <div className="w-full grid grid-cols-12">
                <p className="col-span-3 font-medium">Email:</p>
                <p className="col-span-9">{nurseData.email}</p>
              </div>
              {nurseData.rut && (
                <div className="w-full grid grid-cols-12">
                  <p className="col-span-3 font-medium">Rut:</p>
                  <p className="col-span-9">{nurseData.rut}</p>
                </div>
              )}
              <div className="w-full grid grid-cols-12">
                <p className="col-span-3 font-medium">Teléfono:</p>
                <p className="col-span-9">{nurseData.phone}</p>
              </div>
            </div>
            <div className="w-full flex justify-start items-center gap-4">
              <FormControlLabel
                control={
                  <Switch
                    disabled={!editing}
                    name="¿Tiene huellero?"
                    color="primary"
                    checked={nurseData.fingerprint_available}
                    onChange={(e) => {
                      setEditableNurse((prev) => ({
                        ...prev,
                        fingerprint_available: e.target.checked,
                      }));
                    }}
                  />
                }
                label="¿Tiene huellero?"
              />
              <FormControlLabel
                control={
                  <Switch
                    disabled={!editing}
                    name="Cuenta Falsa"
                    color="primary"
                    checked={nurseData.fake}
                    onChange={(e) => {
                      setEditableNurse((prev) => ({
                        ...prev,
                        fake: e.target.checked,
                      }));
                    }}
                  />
                }
                label="Cuenta Falsa"
              />
            </div>
            <div className="w-full flex flex-col items-start gap-y-2">
              <p className="font-bold text-xl">Laboratorios</p>
              <div className="flex flex-col">
                {!nurseLabs.length && "No esta asignado a laboratorios"}
                <ul className="list-disc">
                  {nurseLabs.map((lab) => (
                    <li key={lab.id}>{lab.display_name}</li>
                  ))}
                </ul>
              </div>
            </div>
            <EditableData
              editing={editing}
              nurseData={nurseData}
              country={country}
              editableData={editableNurse}
              editableActionsServices={editableActionsServices}
              onSetEditableData={(value: Nurse) => setEditableNurse(value)}
              onSetEditableActionsServices={(value: EditableActionsAndServices) => setEditableActionsServices(value)}
            />
          </div>
        )}
      </Flex>
    </SpacedPaper>
  );
};

export default NurseInformation;
