import React, { useEffect, useMemo, useState } from "react";

import clsx from "clsx";
import type { DropResult } from "react-beautiful-dnd";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import sectorsAPI from "~/utils/api/v2/places";
import type { Nurse } from "~/utils/interfaces/Nurse";

type NurseImageProps = {
  active: boolean;
  picture: string;
};

const NurseImage = ({ active, picture }: NurseImageProps) => {
  return (
    <div className={clsx("relative", "basis-2/3 rounded-t-xl overflow-hidden", !active && "opacity-60")}>
      <img
        src={picture}
        alt="Nurse Profile Picture"
        className="h-full w-full rounded-t-xl object-cover"
      />
    </div>
  );
};

type NurseCardProps = {
  id: string;
  names: string;
  picture: string;
  active: boolean;
  selected?: boolean;
  onSelect: (id: string) => void;
};

const NurseCard = ({ id, names, picture, active, selected, onSelect }: NurseCardProps) => {
  return (
    <div
      className={clsx(
        "min-w-[8rem] max-w-[8rem] h-40 relative",
        "flex flex-col justify-end",
        "border-2 border-examedi-gray-line",
        "shadow-sm",
        "bg-white",
        "rounded-xl",
        selected && "scale-105 transition-all duration-150 !border-teal-600",
      )}
      onClick={() => onSelect(id)}
    >
      <div
        className={clsx(
          "absolute w-fit px-1",
          "top-0 right-0 rounded-tr-[0.6rem] rounded-bl-[0.6rem]",
          "text-white text-sm",
          "!opacity-100 z-40",
          active ? "bg-blue-500" : "bg-red-500",
        )}
      >
        {active ? "Activo" : "Inactivo"}
      </div>
      <NurseImage
        active={active}
        picture={picture}
      />
      <div
        className={clsx(
          "h-full w-full",
          "flex flex-col items-center justify-between",
          "basis-1/3",
          !active && "opacity-60",
        )}
      >
        <div className={clsx("w-full", "text-center text-base font-medium", "px-2", "leading-6")}>{names}</div>
      </div>
    </div>
  );
};

type IDroppable = {
  id: string;
  nurses: Nurse[];
};

type NursesProps = {
  allNurses: Nurse[];
  selectedSectorId: string | null;
  selectedNurseId: string | null;
  onSelectNurse: (nurseId: string) => void;
};

const Nurses = ({ allNurses, selectedSectorId, selectedNurseId, onSelectNurse }: NursesProps) => {
  const [assignedNurses, setAssignedNurses] = useState<IDroppable>({ id: "assigned-nurses", nurses: [] });
  const [unassignedNurses, setUnassignedNurses] = useState<IDroppable>({ id: "unassigned-nurses", nurses: [] });
  const [currentFilter, setCurrentFilter] = useState<string>("");

  const [filteredAssignedNurse, filteredUnassignedNurses] = useMemo(() => {
    const loweredFilter = currentFilter.toLowerCase();
    if (currentFilter) {
      const filteredAssigned = assignedNurses.nurses.filter((nurse) => {
        const loweredNames = nurse.names.toLowerCase();
        return loweredNames.includes(loweredFilter);
      });
      const filteredUnassigned = unassignedNurses.nurses.filter((nurse) => {
        const loweredNames = nurse.names.toLowerCase();
        return loweredNames.includes(loweredFilter);
      });
      return [filteredAssigned, filteredUnassigned];
    } else {
      return [assignedNurses.nurses, unassignedNurses.nurses];
    }
  }, [currentFilter, assignedNurses, unassignedNurses]);

  const handleSectorAssignments = async (droppableId: string, nurseId: string) => {
    if (!selectedSectorId) return;

    switch (droppableId) {
      case "assigned-nurses":
        await sectorsAPI.assignNurseToSector(selectedSectorId, nurseId);
        break;
      case "unassigned-nurses":
        await sectorsAPI.unassignNurseToSector(selectedSectorId, nurseId);
        break;
    }
  };

  const handleOnDrop = async (result: DropResult) => {
    const { source, destination, draggableId } = result;
    const setterMapper = {
      [assignedNurses.id]: setAssignedNurses,
      [unassignedNurses.id]: setUnassignedNurses,
    };

    // Dropped outside
    if (!destination) return;

    // Same row case
    if (source.droppableId === destination.droppableId) {
      const setter = setterMapper[destination.droppableId];
      setter((prev) => {
        const foundNurse = prev.nurses.find((nurse) => nurse.id === draggableId) as Nurse;
        const filtered = prev.nurses.filter((nurse) => nurse.id !== foundNurse.id);
        filtered.splice(destination.index, 0, foundNurse);
        return { ...prev, nurses: filtered };
      });
      return;
    }

    // Different row case
    const sourceSetter = setterMapper[source.droppableId];
    const destinationSetter = setterMapper[destination.droppableId];
    sourceSetter((prev) => ({ ...prev, nurses: prev.nurses.filter((nurse) => nurse.id !== draggableId) }));
    destinationSetter((prev) => {
      const foundNurse = allNurses.find((nurse) => nurse.id === draggableId) as Nurse;
      const arrayCopy = [...prev.nurses];
      arrayCopy.splice(destination.index, 0, foundNurse);
      return { ...prev, nurses: arrayCopy };
    });

    // Handle backend operations
    await handleSectorAssignments(destination.droppableId, draggableId);
  };

  useEffect(() => {
    if (allNurses && selectedSectorId) {
      const fetchSectorNurses = async () => {
        const sectorNurses = await sectorsAPI.fetchSectorNurses(selectedSectorId);
        if (sectorNurses) {
          const unassignedNurses = allNurses.filter(
            (nurse) => !sectorNurses.some((sectorNurse) => sectorNurse.id === nurse.id),
          );
          setUnassignedNurses((prev) => ({ ...prev, nurses: unassignedNurses }));
          setAssignedNurses((prev) => ({ ...prev, nurses: sectorNurses }));
        }
      };
      fetchSectorNurses();
    }
  }, [allNurses, selectedSectorId]);

  return (
    <div className="w-full flex flex-col gap-y-2">
      <div className="flex flex-col justify-start items-start gap-y-0">
        <h2>Health Team</h2>
        <ul className="m-0">
          <li className="text-md list-disc">Arrastra a los HT para asignarlos o desasignarlos</li>
          <li className="text-md list-disc">Puedes hacer click en ellos para ver su información detallada</li>
        </ul>
      </div>
      <input
        type="text"
        className={clsx("w-1/2 p-1", "border-2 border-gray-500 rounded-lg")}
        placeholder="Filtrar HTs por nombre"
        onChange={(e) => setCurrentFilter(e.target.value as string)}
      />
      <DragDropContext onDragEnd={handleOnDrop}>
        <h5 className="m-0 font-normal">No asignados</h5>
        <Droppable
          droppableId={unassignedNurses.id}
          direction="horizontal"
        >
          {(droppableProvided, droppableSnapshot) => (
            <div
              ref={droppableProvided.innerRef}
              className={clsx(
                "relative",
                "w-full h-fit min-h-[7rem] py-2 px-2",
                "flex gap-x-3",
                "overflow-auto",
                "bg-gray-200 rounded-lg",
                "border-2 border-dotted border-black",
              )}
            >
              {!filteredUnassignedNurses.length && (
                <div
                  className={clsx(
                    "absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform",
                    droppableSnapshot.isDraggingOver && "opacity-50",
                  )}
                >
                  {!droppableSnapshot.isDraggingOver && "Arrastra a un HT desde abajo para asignarlo"}
                  {droppableSnapshot.isDraggingOver && "Suéltalo aquí"}
                </div>
              )}
              {filteredUnassignedNurses.map((nurse, index) => (
                <Draggable
                  key={nurse.id}
                  draggableId={nurse.id}
                  index={index}
                >
                  {(draggableProvided, draggableSnapshot) => (
                    <div
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      {...draggableProvided.dragHandleProps}
                    >
                      <NurseCard
                        id={nurse.id}
                        names={nurse.names + " " + nurse.last_names}
                        picture={nurse.profile_picture}
                        active={nurse.active}
                        selected={nurse.id === selectedNurseId}
                        onSelect={onSelectNurse}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
        <h5 className="m-0 font-normal">Asignados</h5>
        <Droppable
          droppableId={assignedNurses.id}
          direction="horizontal"
        >
          {(droppableProvided, droppableSnapshot) => (
            <div
              ref={droppableProvided.innerRef}
              className={clsx(
                "relative",
                "w-full h-fit min-h-[7rem] py-2 px-2",
                "flex gap-x-3",
                "overflow-auto",
                "bg-gray-200 rounded-lg",
                "border-2 border-dotted border-black",
              )}
            >
              {!filteredAssignedNurse.length && (
                <div
                  className={clsx(
                    "absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform",
                    droppableSnapshot.isDraggingOver && "opacity-50",
                  )}
                >
                  {!droppableSnapshot.isDraggingOver && "Arrastra a un HT desde arriba para asignarlo"}
                  {droppableSnapshot.isDraggingOver && "Suéltalo aquí"}
                </div>
              )}
              {filteredAssignedNurse.map((nurse, index) => (
                <Draggable
                  key={nurse.id}
                  draggableId={nurse.id}
                  index={index}
                >
                  {(draggableProvided, draggableSnapshot) => (
                    <div
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      {...draggableProvided.dragHandleProps}
                    >
                      <NurseCard
                        id={nurse.id}
                        names={nurse.names + " " + nurse.last_names}
                        picture={nurse.profile_picture}
                        active={nurse.active}
                        selected={nurse.id === selectedNurseId}
                        onSelect={onSelectNurse}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default Nurses;
