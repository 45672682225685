import React, { useRef, useState } from "react";

import Button from "@material-ui/core/Button";
import Swal from "sweetalert2";

import sectorsAPI from "~/utils/api/v2/places";
import { CODE_TO_COUNTRY } from "~/utils/data/constants";
import type { Locale } from "~/utils/interfaces/Locale";

type UpserterProps = {
  country: Locale;
  showManualUpsertButton?: boolean;
  fetchSectorsCallback: () => Promise<void>;
};

const Upserter = ({ country, showManualUpsertButton, fetchSectorsCallback }: UpserterProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [file, setFile] = useState<File>();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length === 1) {
      const fileToUpload = event.target.files[0];
      if (fileToUpload) {
        setFile(fileToUpload);
      }
    }
  };

  const handleUpload = async () => {
    if (!file) return;
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("country", country);
    await sectorsAPI.manualUpsert(formData);
    await Swal.fire({
      icon: "success",
      title: "Sectores actualizados",
    });
    await fetchSectorsCallback();
    setLoading(false);
    setFile(undefined);
  };

  const handleForceUpsert = async () => {
    const res = await sectorsAPI.forceUpsert(country);
    if (res) {
      await fetchSectorsCallback();
      await Swal.fire({
        icon: "success",
        title: `Sectores actualizados para ${CODE_TO_COUNTRY[country]}`,
      });
    } else {
      await Swal.fire({
        icon: "error",
        title: "Ocurrió un error intentando forzar la actualización",
      });
    }
  };

  return (
    <div className="w-full py-2 flex flex-col gap-y-2">
      <input
        type="file"
        accept="text/xml"
        disabled={loading}
        onChange={handleFileInputChange}
        ref={inputRef}
        hidden
      />
      <div className="w-full flex justify-start gap-x-3">
        <Button
          variant="contained"
          color="primary"
          style={{ color: "black" }}
          onClick={handleForceUpsert}
        >
          Forzar actualización de sectores
        </Button>
        {!!file && showManualUpsertButton && (
          <Button
            variant="contained"
            color="primary"
            style={{ color: "black" }}
            onClick={handleUpload}
          >
            Subir
          </Button>
        )}
        {!file && showManualUpsertButton && (
          <Button
            variant="contained"
            color="primary"
            style={{ color: "black" }}
            onClick={handleClick}
          >
            Subir KML para {CODE_TO_COUNTRY[country]}
          </Button>
        )}
      </div>
    </div>
  );
};

export default Upserter;
