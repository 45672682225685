import React from "react";

import { Sector } from "~/utils/interfaces/Places";

type RightHeaderProps = {
  sector: Sector | null;
};

const RightHeader = ({ sector }: RightHeaderProps) => {
  if (!sector) return null;

  return (
    <div className="w-full flex flex-col justify-center items-bottom">
      <div className="w-full flex">
        <p className="basis-1/5 text-3xl font-bold">Grupo:</p>
        <p className="basis-4/5 text-3xl font-normal">{sector.group}</p>
      </div>
      <div className="w-full flex">
        <p className="basis-1/5 text-3xl font-bold">Sector:</p>
        <p className="basis-4/5 text-3xl font-normal">{sector.name}</p>
      </div>
    </div>
  );
};

export default RightHeader;
